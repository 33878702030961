<template>
    <div class="e-bg bg-dark bg-opacity-50 w-100 h-100" v-if="activate" @click="showBox"></div>
    <div class="searchJob w-100 bg-white" v-if="showScreen">
        <div class="d-flex p-2 bg-hr bg-opacity-50">
            <div>
                <i class="fa-solid fa-angle-left text-white px-2" @click="goBack"></i>
                <span class="ms-4 text-white">{{ joblistTitle }}</span>
            </div>
            <div class="ms-auto">
                <i class="fa-solid fa-magnifying-glass text-white px-2" @click="search"></i>
            </div>
        </div>
        <div class="d-flex justify-content-evenly py-2 border-bottom">
            <div :class="{ 'text-hr': activate }" @click="activate = !activate">
                筛 选
                <i class="fa-solid fa-caret-down" :class="{ 'text-hr': activate }"></i>
            </div>
            <div>
                排 序
                <i class="fa-solid fa-caret-down"></i>
            </div>
        </div>
        <div v-if="activate">
            <!-- 工作类型 -->
            <!-- <div class="p-2 list">
            <div class="ms-1 py-1">工作类型</div>
            <div class="btn-group list text-center" role="group" aria-label="Basic radio toggle button group">
                <div v-for="(value,index) in POSITION_TYPE" :key="index">
                    <input type="radio" class="btn-check" name="btnradio1" :id="'btn1' + index" autocomplete="off" @click="job(index)">
                    <label class="btn btn-outline-success btn-sm m-1 radius-5" :for="'btn1' + index">{{ value }}</label>
                </div>
            </div>
            </div>-->
            <!-- 发薪方式 -->
            <div class="p-2 list">
                <div class="ms-1 py-1">发薪方式</div>
                <div
                    class="btn-group list text-center flex-wrap"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio2"
                            :id="'btn2'"
                            autocomplete="off"
                            @click="settimeout(null)"
                        />
                        <label class="btn btn-outline-success btn-sm m-1 radius-5" :for="'btn2'">不 限</label>
                    </div>
                    <div v-for="(value, index) in SETTLEMENT_TYPE" :key="index">
                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio2"
                            :id="'btn2' + index"
                            autocomplete="off"
                            @click="settimeout(index)"
                        />
                        <label
                            class="btn btn-outline-success btn-sm m-1 radius-5"
                            :for="'btn2' + index"
                        >{{ value }}</label>
                    </div>
                </div>
            </div>
            <div class="p-2 list">
                <div class="ms-1 py-1">年 龄</div>
                <div
                    class="btn-group list text-center flex-wrap"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <div v-for="(value, index) in ages" :key="index">
                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio3"
                            :id="'btn3' + index"
                            autocomplete="off"
                            @click="age(value.id)"
                        />
                        <label
                            class="btn btn-outline-success btn-sm m-1 radius-5"
                            :for="'btn3' + index"
                        >{{ value.name }}</label>
                    </div>
                </div>
            </div>
            <div class="p-2 list">
                <div class="ms-1 py-1">其 它</div>
                <div
                    class="btn-group list text-center flex-wrap"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <div v-for="(value, index) in others" :key="index">
                        <input
                            type="radio"
                            class="btn-check"
                            name="btnradio4"
                            :id="'btn4' + index"
                            autocomplete="off"
                            @click="other(value.id)"
                        />
                        <label
                            class="btn btn-outline-success btn-sm m-1 radius-5"
                            :for="'btn4' + index"
                        >{{ value.name }}</label>
                    </div>
                </div>
            </div>
            <div class="text-end pe-3">
                <button class="btn btn-outline-success btn-sm m-2" @click="getScreen">确 定</button>
                <button class="btn btn-outline-success btn-sm m-2" @click="whole">全部职位</button>
                <button
                    class="btn btn-outline-secondary btn-sm m-2"
                    @click="activate = !activate"
                >取 消</button>
            </div>
        </div>
    </div>
    <div class="searchJob w-100 bg-white" v-if="showSearch">
        <div class="d-flex p-2 bg-hr bg-opacity-50 w-100">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-angle-left text-white px-2" @click="goBack"></i>
            </div>
            <div class="ms-auto d-flex w-100 align-items-center">
                <input
                    type="search"
                    class="form-control w-100"
                    placeholder="请输入关键字"
                    v-model="searchValue"
                />
                <i class="fa-solid fa-magnifying-glass text-white px-2" @click="sendSearch"></i>
            </div>
        </div>
        <div class="p-3" v-if="showBtn">
            <div class="list" role="group" aria-label="Basic radio toggle button group">
                <span
                    class="m-1 bg-success px-2 bg-opacity-10"
                    v-for="(value, index) in searchHistory"
                    :key="index"
                    @click="sendSearch(value)"
                >{{ value }}</span>
            </div>
            <div class="text-end mt-2">
                <button
                    class="btn btn-outline-secondary btn-sm"
                    v-if="showBtn"
                    @click="clearHistory"
                >清除历史</button>
            </div>
        </div>
    </div>
    <div :class="{ 'screen': showScreen, 'ssearch': showSearch }">
        <Joblist :data="data"></Joblist>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Axios from 'axios'
import { SETTLEMENT_TYPE } from '@/lib/data'
import { saveLocalStor, readLocalStor, deleteAllData } from '@/lib/unitls'
import Joblist from '@/components/joblist.vue'
import { offset } from '@popperjs/core'

const route = useRoute()
const router = useRouter()

const joblistTitle = ref('职位大厅')
const activate = ref(false)
const showScreen = ref(true)
const showSearch = ref(false)
const sendObj = ref({})
const searchValue = ref()
const ages = [
    { id: null, name: '不 限' },
    { id: '< 18', name: '18岁以下' },
    { id: '> 18', name: '18岁以上' },
    { id: '> 35', name: '35岁以上' },
    { id: '> 50', name: '50岁以上' }
]
const others = [
    { id: null, name: '不 限' },
    { id: 'dayClass', name: '长白班' },
    { id: 'eatLive', name: '包吃住' },
    { id: 'nationMin', name: '少数民族' }
]
function showBox() {
    activate.value = !activate.value
    showScreen.value = !showScreen.value
    showSearch.value = !showSearch.value
}
function goBack() {
    router.push(history.state.back)
}
// function job(a){
//     jobType.value = a
//     // alert(a)
// }
//结算类型
function settimeout(a) {
    if (a == null) {
        delete sendObj.value.settimeout
        return
    } else {
        sendObj.value.settimeout = a
    }
}
//年龄条件
function age(a) {
    if (a == null) {
        delete sendObj.value.ages
        return
    } else {
        sendObj.value.ages = a
    }
}
//其它
function other(a) {
    if (a == null) {
        delete sendObj.value.other
        return
    } else {
        sendObj.value.other = a
        console.log(sendObj.value)
    }
}
//确定提交
function getScreen() {
    activate.value = !activate.value
    getData(sendObj.value)
}
//全部职位
function whole() {
    activate.value = !activate.value
    getData()
}
//搜索
const searchHistory = ref([])
const showBtn = ref(false)
function search() {
    if (readLocalStor('searchH')) {
        searchHistory.value = readLocalStor('searchH').split(',')
        showBtn.value = true
    }
    showScreen.value = !showScreen.value
    showSearch.value = !showSearch.value
    activate.value = !activate.value
}
function sendSearch(val) {
    if (val == 'string') {
        getData({ search: val })
    } else {
        if (searchValue.value) {
            if (searchHistory.value.indexOf(searchValue.value) == -1) {
                searchHistory.value.push(searchValue.value)
                saveLocalStor({ searchH: searchHistory.value })
            }
            getData({ search: searchValue.value })
        }
    }

    activate.value = !activate.value
    showScreen.value = !showScreen.value
    showSearch.value = !showSearch.value
}
function clearHistory() {
    deleteAllData()
    searchHistory.value = []
    showBtn.value = false
}
const data = ref([])

const a = 10

for (var i = 0; i < a; i++) {
    data.value.push(
        {
            id: 100 + i,
            name: '97打工网' + i, // Number 岗位名称
            tags: [1, 3, 4], // array 岗位优势
            salarys: 2, //Number 工资范围
            settlement: 0, // Number 结算方式
            price: 20 + i, // number 小时工单价
            logo: 'https://picsum.photos/70/70' // Strying 企业logo
        }
    )
}

function getData(obj) {
    Axios.get('http://localhost:5000/jobs', obj).then((response) => {
        if (response.data.code == 0) {
            data.value = response.data.data
        }
    })
}

onMounted(() => {
    window.scrollTo(0, 0)
    document.title = "工作大厅@97打工网"
    if (route.query.type) {
        joblistTitle.value = route.query.title
        getData(route.query.type)
    } else {
        getData()
    }

})


</script>

<style scoped>
.searchJob {
    position: fixed;
    top: 0;
    z-index: 9999;
}
.searchJob .list a,
label {
    width: 80px;
}
.e-bg {
    position: fixed;
    top: 0;
    z-index: 8888;
}
.screen {
    margin-top: 90px;
}
.ssearch {
    margin-top: 60px;
}
</style>